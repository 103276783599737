import { render, staticRenderFns } from "./mallshopdetails.vue?vue&type=template&id=131f6427&scoped=true"
import script from "./mallshopdetails.vue?vue&type=script&lang=js"
export * from "./mallshopdetails.vue?vue&type=script&lang=js"
import style0 from "./mallshopdetails.vue?vue&type=style&index=0&id=131f6427&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "131f6427",
  null
  
)

export default component.exports